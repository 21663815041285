@font-face {
  font-family: "Juanita";
  src: local("JuanitaRegular"),
    url(./assets/fonts/JuanitaRegular.woff) format("woff"); /* Pretty Modern Browsers */
}

@font-face {
  font-family: "Juanita Condensed";
  src: local("JuanitaCondensed"),
    url(./assets/fonts/JuanitaCondensed.woff) format("woff"); /* Pretty Modern Browsers */
}

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: "Roboto", "Helvetica", sans-serif;
  font-size: 18px;
}

body.order-form {
  margin-bottom: 100px;
}
.Mui-error {
  font-family: "Roboto", "Helvetica", sans-serif;
}
.react-pdf__Page__annotations,
.react-pdf__message {
  display: none;
}
.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}
@media screen and (max-width: 600px) {
  body.order-form {
    margin-bottom: 79px;
  }
  .Hidden600 {
    display: none;
  }
}
@media screen and (min-width: 600px) {
  .hidden600 {
    display: none;
  }
}
#root {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}
