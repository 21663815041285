.container {
  padding: 5% 0 5% 8px;
  background: #edf9ff;
}
.h2 {
  color: #1b1b1c;
  font-family: PT Sans Narrow;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.26px;
  text-transform: capitalize;
  margin: -16px 0 18px;
}
.OurGoal {
  display: flex;
  flex: 1 1;
  gap: 10%;
  color: var(--000000, rgba(0, 0, 0, 0.54));
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.OurGoal > div {
  margin: auto;
}
.OurGoal img {
  max-width: 50%;
  flex: 1;
}
.container > :nth-child(2) {
  padding-top: 5%;
}
@media screen and (max-width: 778px) {
  .OurGoal {
    flex-direction: column;
    text-align: center;
    padding: 0 10%;
    font-size: 16px;
  }
  .OurGoal img {
    max-width: 100%;
  }
  .container :last-child {
    flex-direction: column-reverse;
    gap: 32px;
  }
}
@media screen and (max-width: 480px) {
  .OurGoal {
    padding: 0;
    gap: 32px;
  }
  .h2 {
    font-size: 26px;
    margin-top: 0;
  }
}
