.agent {
  color: black;
  padding: 15px;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s;
  min-height: 100%;
}

.agent__img {
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.agent__img-container {
  background-color: #fff;
  padding-top: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 50%;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
}

.agent:hover {
  background-color: silver;
  color: #fff;
}
.agent.selected {
  background-color: #00557e;
  color: #fff;
}

.order_datepicker_div .react-datepicker__input-container input {
  border: 0px;
  width: 100%;
  padding: 15px 0px 7px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  font-size: 1rem;
}
.order_datepicker_div .react-datepicker__input-container input.error {
  border-bottom-color: #f44336 !important;
}
.order_datepicker_div .react-datepicker__input-container input:focus {
  outline: none;
}

.order_datepicker_div .react-datepicker__input-container input::after {
  border-bottom: 2px solid rgb(0, 59, 88);
}

.order_datepicker_div label {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.54);
  justify-content: left;
  display: flex;
}

.order_datepicker_div label.error {
  color: #f44336;
}
.order_datepicker_div .react-datepicker-wrapper {
  display: flex;
}

.payment_dialog_div_scroll div:nth-child(3) {
  max-height: calc(100% - 64px) !important;
  /* margin: 48px; */
}

@media (max-width: 960px) {
  .css-1knkj9d-common-section {
    height: 120px !important;
  }

  .css-1knkj9d-common-section h1 {
    font-size: 20px;
  }

  .css-1knkj9d-common-section h2 {
    font-size: 18px;
  }

  .card_border {
    height: auto !important;
  }
  .payment_btn_div {
    margin-bottom: 1.5rem;
  }
}

.card_border {
  height: 300px;
}
.content_div {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.sub_headng {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-weight: 700;
  font-family: "PT Sans Narrow", "Roboto", sans-serif;
  line-height: 1.5em;
  text-transform: capitalize;
}

.sub_detail {
  color: rgba(0, 0, 0, 0.54);
  word-wrap: break-word;
}
