.container div {
  padding-top: 0;
  margin-top: 0;
  /* margin-bottom: 32px; */
}
.container > div:first-child {
  padding-left: 0% !important;
  padding-right: 0% !important;
}
.container > div:first-child > div:nth-last-child(-n + 2) {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.container {
  margin-bottom: 40px;
  width: 100%;
}
